.CustomButton {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: ease-out 0.5s;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
}

.CustomButton.CustomButton-border::after,
.CustomButton.CustomButton-border::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  transition: 0.5s;
}

.CustomButton.CustomButton-border::after {
  top: 0px;
  left: 0px;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
  border-radius: 8px;
}

.CustomButton.CustomButton-border::before {
  bottom: 0px;
  right: 0px;
  border-bottom: 3px solid transparent;
  border-right: 3px solid transparent;
  border-radius: 8px;
}

.CustomButton.CustomButton-border:hover {
  color: rgb(17 106 183);
  background-color: transparent;
}

.CustomButton.CustomButton-border:hover::after,
.CustomButton.CustomButton-border:hover::before {
  width: 100%;
  height: 100%;
  border-color: rgb(17 106 183);
}
