.xpt *,
.xpt *:after,
.xpt *:before {
    -webkit-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -ms-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;
}

.xpt-hex {
    position: relative;
    margin: 80px 0px 75px 0px;
}

.xpt-hex:after {
    content: "";
    position: absolute;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
}

.xpt-hex:before {
    content: "";
    position: absolute;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.xpt-hex,
.xpt-hex:after,
.xpt-hex:before {
    width: 250px;
    height: 150px;
    background: rgb(17 106 183);
    background-position: 0%;
    text-align: justify;
    border-radius: 10px;
}


.ex-2 {
    position: absolute;
    z-index: 1;
    bottom: -48%;
    background-color: rgba(0, 0, 0, 0.174);
    clip-path: polygon(50% 0, 100% 25%, 100% 76%, 50% 100%, 0 76%, 0 25%)
}

.ex-2::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 95%;
    background-color: rgba(17, 106, 183, 0.409);
    top: 0;
    clip-path: polygon(50% 0, 100% 26%, 100% 0%, 0 0%, 0 26%);

}

.ex-2::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 95%;
    background-color: rgba(17, 106, 183, 0.444);
    bottom: 0;
    transform: rotate(180deg);

    clip-path: polygon(50% 0, 100% 0%, 100% 0%, 0 0%, 0 0%);

}

.xpt-hex:hover .ex-2::before {
    clip-path: polygon(50% 0, 100% 26%, 100% 54.4%, 0 54.4%, 0 26%);
}

.xpt-hex:hover .ex-2::after {
    clip-path: polygon(50% 0, 100% 26%, 100% 51%, 0 51%, 0 26%);

    /* height: 0%; */
}

.xpt-ihex {
    position: absolute;
    z-index: 1;
    left: 12px;
    right: 12px;
    top: 10px;
    bottom: 6px;
}

.xpt-ihex>img {
    position: absolute;
    top: -50%;
}

.xpt-ihex:after {
    content: "";
    position: absolute;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
}

.xpt-ihex:before {
    content: "";
    position: absolute;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.xpt-ihex:after,
.xpt-ihex:before {
    width: 100%;
    height: 100%;
}

.xpt-hex.xpt-cw:hover,
.xpt-hex.xpt-acw:hover .xpt-ihex {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
}

.xpt-hex.xpt-cw:hover .xpt-ihex,
.xpt-hex.xpt-acw:hover {
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

@keyframes xptzoom-in-out {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(.4, .4);
        -moz-transform: scale(.4, .4);
        -ms-transform: scale(.4, .4);
        -o-transform: scale(.4, .4);
        transform: scale(.4, .4);
    }

    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}


/* .xpt .ex-2 .f-icon{
    top: 100%;
    left: 13%;

}
.xpt:hover .f-icon{
    top: 50%;
    transition: all .6s;
    
}

.xpt .ex-2 .s-icon{
    top: 100%;
    left: 33%;

}
.xpt:hover .s-icon{
    top: 50%;
    transition: all .8s;
  
}

.xpt .ex-2 .t-icon{
    top: 100%;
    left: 53%;

}
.xpt:hover .t-icon{
    top: 50%;
    transition: all 1.1s;
  
}

.xpt .ex-2 .four-icon{
    top: 100%;
    left: 73%;

}
.xpt:hover .four-icon{
    top: 50%;
    transition: all 1.5s;
  
} */