.card::before {
  content: "";
  position: absolute;
  border-radius: 24px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -999;
  background-color: rgb(177, 177, 236);
  transition: transform 500ms ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

.card:hover::before,
.card:focus::before {
  transform: scaleX(0.6);
}

.card::after {
  content: "";
  position: absolute;
  border-radius: 24px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -999;
  background-color: rgb(177, 177, 236);
  transition: transform 500ms ease-in-out;
  transform: scaleX(0);
  transform-origin: right;
}

.card:hover::after,
.card:focus::after {
  transform: scaleX(0.6);
}

.card {
  z-index: 1;
}

.animate-stboy {
  animation: stboy 5000ms linear none infinite;
}

@keyframes stboy {
  0% {
    translate: 0 0;
  }

  25% {
    translate: 30px 0;
  }

  50% {
    translate: 0 0;
  }

  75% {
    translate: -30px 0;
  }

  100% {
    translate: 0 0;
  }
}

@media (max-width: 575px) {
  @keyframes stboy {
    0% {
      translate: 0 0;
    }

    25% {
      translate: 0 30px;
    }

    50% {
      translate: 0 0;
    }

    75% {
      translate: 0 -30px;
    }

    100% {
      translate: 0 0;
    }
  }
}

.footerlink {
  width: fit-content;
  position: relative;
  transition: padding-left 800ms;
  font-size: 20px;
}

@media (max-width: 767px) {
  .footerlink,
  .footerlink a {
    font-size: 16px;
  }
}

.footerlink:hover {
  padding-left: 20px;
}

/* .footer-hover-line {
  width: 0px;
  height: 3px;
  position: absolute;
  left: 0;
  top: 12px;
  background-color: rgb(17 106 183);
  border-radius: 8px;
  transition: width 800ms;
} */
.footer-hover-line {
  width: 0px;
  height: 3px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 8px;
  transition: width 800ms;
}

.footerlink:hover .footer-hover-line {
  width: 15px;
}

.navlinks-active::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  border-bottom: solid 3px rgba(17, 106, 183, 0.65);
}

.navlinks {
  position: relative;
}

.navlinks:hover {
  color: rgb(17 106 183);
}

.navlinks:hover:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  border-bottom: solid 3px rgba(17, 106, 183, 0.65);
  animation: border_anim 0.25s linear forwards;
}

@keyframes border_anim {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none !important;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Remove arrow from number input */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Offer icons */

.offer-icons {
  border: 2px solid transparent;
}

.offer-icons svg {
  min-width: 65px;
  min-height: 75px;
}

/* .offer-icons:hover {
  border: 2px solid #293485;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.offer-icons:hover #Path_4564,
.offer-icons:hover #svgexport-7_41_ {
  opacity: 1;
  transition: all 200ms ease-in-out;
}

.offer-icons:hover #Path_4568 {
  fill: white;
  transition: all 200ms ease-in-out;
} */

.offer-icons {
  border: 2px solid #293485;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.offer-icons #Path_4564,
.offer-icons #svgexport-7_41_ {
  opacity: 1;
  transition: all 200ms ease-in-out;
}

.offer-icons #Path_4568 {
  fill: white;
  transition: all 200ms ease-in-out;
}

/* scrolltop-btn */

.scrolltop-btn .scrolltop-img {
  transition: transform 200ms ease-in-out;
}

.scrolltop-btn:hover .scrolltop-img {
  transform: scale(1.5) translate(0, -20px);
}

.scrolltop-btn::before {
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(17, 106, 183, 0.2);
  }

  50% {
    box-shadow: 0 0 0 30px rgba(255, 109, 74, 0);
  }

  100% {
    box-shadow: none;
  }
}

.rocket-launched {
  animation: rocket-launch 600ms forwards linear;
}

@keyframes rocket-launch {
  0% {
    transform: scale(1.5) translate(15px, -20px);
    opacity: 1;
  }

  50% {
    transform: scale(1.5) translate(15px, -200px);
    opacity: 0.5;
  }

  100% {
    transform: scale(1.5) translate(15px, -400px);
    opacity: 0;
  }
}

.rocket-launched-bg {
  animation: rocket-launch-bg 600ms forwards linear;
}

@keyframes rocket-launch-bg {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: translate(0, 40px);
    opacity: 0;
  }
}

/* Chat Button */

.chat-icon {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

/* Home */

/* .animate-bulb {
    animation: up-down linear infinite 5s;
}

@keyframes up-down {
    0% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(0, 20px);
    }

    50% {
        transform: translate(0, 0);
    }

    75% {
        transform: translate(0, -20px);
    }
} */

/* Portfollio Projects */

.projects {
  animation: animate-projects 10s linear infinite;
  animation-play-state: running;
}

@keyframes animate-projects {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-13%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Rounded Carousel */

.carousel__slide-overlay {
  display: none !important;
}

.carousel__slide {
  border: none !important;
  width: auto !important;
  height: auto !important;
}

.carousel__slide img {
  user-select: none !important;
}

/* MUI snackbar */

.MuiAlert-icon {
  align-items: center;
}

/*  */

.budget input[type="radio"] {
  display: none;
}

.budget input:checked + label {
  border: solid 1px;
  color: white;
  background-color: #116ab7;
}

.mini-carousel .owl-item {
  padding: 20px 10px;
}

.mini-carousel .owl-item > svg {
  margin: 0 auto;
}

/* accordian */
.portfolioContent {
  overflow: hidden;
  max-height: 0;
}
.portfolioContent.active {
  overflow: hidden;
  max-height: 100%;
}

/* hiding tiny editor logo */
.tox-statusbar__branding {
  height: 0;
  width: 0;
  overflow: hidden;
  display: none;
}
/* button disabled */
[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}



.react-tel-input .selected-flag , .react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px  !important;
}