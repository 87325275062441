@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer components {
  .homeIcons {
    @apply absolute aspect-square z-[69] transition-all;
  }
}

* {
  margin: 0;
  padding: 0;
  
  font-weight: normal;
  font-size: 20px;
}

@font-face {
  /* font-family: Gumela;
  src: url("/public/assets/fonts/Gumela\ Regular.otf"); */
  font-family: 'Poppins', sans-serif;
}

/* For owl carousel image */

.owl-carousel .owl-item img {
  width: auto !important;
}

@layer utilities {
  .pause {
    animation-play-state: paused;
  }
}

img {
  pointer-events: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}